<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <OpenPension msg="Welcome to Your Vue.js App"/> -->
    <router-view />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import OpenPension from './components/OpenPension.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
    // OpenPension
  },
  created(){
    console.log('微信', this.$isWechat());
    console.log('支付宝', this.$isAlipay());
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
* {
    margin: 0;
    padding: 0;
  }
</style>
