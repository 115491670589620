import Vue from 'vue'
import App from './App.vue'

import 'lib-flexible'

import ElementUI from 'element-ui'

Vue.use(ElementUI);

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole);

import router from './router'

import { PhoneNumberServer } from 'aliyun_numberauthsdk_web';  //ES6
Vue.prototype.phoneNumberServer = new PhoneNumberServer();

import axios from 'axios'
// Vue.use(axios)
Vue.prototype.axios = axios;

Vue.config.productionTip = false

Vue.prototype.$isWechat = function () {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') !== -1;
};

Vue.prototype.$isAlipay = function () {
  return window.AlipayJSBridge !== undefined;
};

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
