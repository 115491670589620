<template>
  <div id="pay">
    <!-- <div v-show="!authorStatus" class="pay-login">
      <img class="loginImg" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417171633173.png" alt="">
      <div class="login-phone">
        <input type="number" value="" v-model="mobile" placeholder="请输入手机号" placeholder-style="color:#999;" oninput="if(value.length>11)value=value.slice(0,11)"/>
        <div class="get-code" @click="getCode">{{ getCodeText }}</div>
      </div>
      <div class="login-phone">
        <input type="number" value="" v-model="mobileCode" placeholder="请输入验证码" placeholder-style="color:#999;" />
      </div>
      <div class="login-btn" @click="bindPhone(mobile, 2)">登录</div>
    </div> -->
  </div>
</template>

<script>
// import { PhoneNumberServer } from 'aliyun_numberauthsdk_web';
// getAuthToken, getPhoneWithToken, binding,
// import {wxcheck, wechatAuthorization, aliSign, alicheck, userPhoneLogOnCode} from '../api/index'
import {wechatAuthorization, aliSign, alicheck, userPhoneLogOnCode} from '../api/index'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      userpay: null,
      userInfor: {},
      aliUserInfor: {},
      aliAccount: {},
      merContent: {
        mer_name: '',
        mer_banner: ''
      },
      paymentAmount: '',
      trade_type: '',

      // authorStatus:true,

      // mobile: "", // 手机号
      // mobileCode: '', // 验证码
      //   getCodeText: '获取验证码', // 获取验证码按钮文字
      //   getCodeisWaiting: false, // 获取验证码按钮状态
    }
  },
  created() {
    //获取参数
    this.userpay = this.parseParms();
    if (this.userpay != undefined) {
      localStorage.setItem('userpay', JSON.stringify(this.userpay));
    }


    //跳转微信授权登陆
    let local = window.location.href;
    if (local.split(':')[0] == 'http') {
      local = local.split(':')[0] + 's:' + local.split(':')[1]
    } else {
      local = window.location.href;
    }
    //微信跳转链接        // wx66f5f8def2b755da
    let wechatHrefurl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + 'wx6ac5b448b75117d9' +
        "&redirect_uri=" + encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_userinfo&forcePopup=true&forceSnapShot=true&state=STATE#wechat_redirect";

    //不是微信并且不是支付宝
    if (!this.$isWechat() && !this.$isAlipay()) {
      alert('请使用微信或支付宝扫码');
    }
    //判断是不是微信
    if (this.$isWechat()) {
      this.trade_type = "WX"
      if ((!this.userpay.code || this.userpay.code == "" || this.userpay.code == null) && localStorage.getItem('userInfor') != null) {
        this.userInfor = JSON.parse(localStorage.getItem('userInfor'));
        // console.log(this.userInfor.openid)
        if(this.userInfor.openid!=undefined){
          this.initWxConfig();
        }else{
          window.location.href = wechatHrefurl;
        }
      } else  if (!this.userpay.code || this.userpay.code == "" || this.userpay.code == null) {
        window.location.href = wechatHrefurl;
      } else {
        this.user()
      }
    }else if (this.$isAlipay()) {    //判断是不是支付宝
      this.trade_type = "ALI"

      local = local.replace('#/', '');
      let aliHrefurl ="https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021003191617008&scope=auth_user&redirect_uri=" +encodeURIComponent(local)

      if ((!this.userpay.auth_code || this.userpay.auth_code == null || this.userpay.auth_code == "") && localStorage.getItem('aliAccount') != null) {
          this.aliAccount = JSON.parse(localStorage.getItem('aliAccount'));
          if(this.aliAccount.user_id!=undefined){
            this.initAliConfig( this.aliAccount.user_id);
          }else{
            window.location.href = aliHrefurl;
          }

      } else if (!this.userpay.auth_code || this.userpay.auth_code == null || this.userpay.auth_code == "") {
        window.location.href = aliHrefurl;
      } else {
        this.aliUser()
      }
    }
  },
  // 引入支付宝jsapi
  mounted() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
    document.body.appendChild(s);
    this.readys(() => {
      // alert('支付宝 H5 SDK 已初始化');
    });
    window.onload = () => {   //初始化产生一个全局变量 AlipayJSBridge
      this.readys()
    }
  },
  methods: {
    readys(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener('AlipayJSBridgeReady', callback, false);
      }
    },
    getCode() {
      if (this.getCodeisWaiting) return;

      if (!/^1[2-9]\d{9}$/.test(this.mobile)) {
        alert(!this.mobile ? '请输入手机号' : '请填写正确手机号码')
        return false;
      }
      this.getCodeText = "发送中..."
      this.getCodeisWaiting = true;
      userPhoneLogOnCode({phone: this.mobile}).then(res => {
        console.log(res);
        if (res.status == 200) {
          // alert('发送成功，请注意查收')
          this.setTimer();
        } else {
          alert(res.message)
          this.getCodeText = '获取验证码';
          this.getCodeisWaiting = false;
        }
      }).catch(res => {
        alert(res.message)
      })
    },
    setTimer() {
      clearInterval(this.Timer);
      let holdTime = 60;
      this.getCodeText = "重新获取(60)"
      this.Timer = setInterval(() => {
        if (holdTime <= 0) {
          this.getCodeisWaiting = false;
          this.getCodeText = "获取验证码"
          clearInterval(this.Timer);
          return;
        }
        this.getCodeText = "重新获取(" + holdTime + ")"
        holdTime--;

      }, 1000)
    },
    user() {
        wechatAuthorization(this.userpay.code).then(res => {
          console.log(res);
          if (res.status == 200) {
            this.userInfor = res.data;
            localStorage.setItem('userInfor', JSON.stringify(this.userInfor));
            this.initWxConfig();
          }
        }).catch(res => {
          alert(res.message)
        })

    },
    aliUser() {
        // console.log(this.userpay, this.userpay.code);
        aliSign({code: this.userpay.auth_code, type: "h5pay"}).then(res => {

          if (res.status == 200) {
            this.aliUserInfor = res.data;
            localStorage.setItem('aliAccount', JSON.stringify(this.aliUserInfor));
            this.initAliConfig(res.data.user_id);
          }
        }).catch(res => {
          alert(res.message)
        })

    },
    // 微信授权登录
    initWxConfig() {
      if (this.userpay.type == "hong") {
        // localStorage.setItem("token", res.data.token);
        this.$router.push({
          path: '/openPension',
          query: {userpay: this.userpay, userInfor: this.userInfor, aliAccount: this.aliAccount}
        });
      } else {
        this.$router.push({
          path: '/orderPay',
          // query: {userpay: this.userpay, userInfor: this.userInfor, aliAccount: this.aliAccount}
        });
      }
      /*******注释掉微信登录 - 测试能不解决扫码支付每次都需要授权问题********/
      // wxcheck(userOpenid).then(res => {
      //   console.log(res);
      //   // if(res.status == 400){
      //   //   // 未绑定手机号，获取token，绑定手机号
      //   //   this.getALToken();
      //   // }
      //   // if(res.status == 200){
      //     // 已绑定手机号，获取商户
      //     // this.getMer();

      //     if(this.userpay.type == "hong"){
      //       // localStorage.setItem("token", res.data.token);
      //       this.$router.push({path: '/openPension', query: {userpay: this.userpay, userInfor: this.userInfor, aliAccount: this.aliAccount}});
      //     }else{
      //       this.$router.push({path: '/orderPay', query: {userpay: this.userpay, userInfor: this.userInfor, aliAccount: this.aliAccount }});
      //     }

      //   // }
      // }).catch(res => {
      //   alert(res.message)
      // })
    },
    // 支付宝授权登录
    initAliConfig(userOpenid) {
      alicheck(userOpenid).then(res => {
        if (res.status == 200) {

          // if(res.data.code == 400){
          //   // 未绑定手机号，获取token，绑定手机号

          //   this.getALToken();

          // }else{
          // 已绑定手机号，获取商户
          this.aliAccount = res.data.user;
          // this.getMer();
          if (this.userpay.type == "hong") {
            // localStorage.setItem("token", res.data.token);
            this.$router.push({
              path: '/openPension',
              query: {userpay: this.userpay, userInfor: this.userInfor, aliAccount: this.aliAccount}
            });
          } else {
            this.$router.push({
              path: '/orderPay',
              // query: {userpay: this.userpay, userInfor: this.userInfor, aliAccount: this.aliAccount}
            });
          }
          // }

        }
      }).catch(res => {
        alert(res.message)
      })
    },
    // // 阿里一键登录
    // getALToken(){
    //   let that = this;
    //   getAuthToken({url:"https://offlinepay-test.bjtdba.com/", origin:"https://offlinepay-test.bjtdba.com"}).then(resT => {
    //     console.log(resT);
    //     var phoneNumberServer = new PhoneNumberServer();

    //       phoneNumberServer.checkLoginAvailable({
    //           accessToken: resT.data.TokenInfo.AccessToken,
    //           jwtToken:resT.data.TokenInfo.JwtToken,
    //           success:function(data){
    //               console.log(data);
    //                   if(data.code===600000){
    //                       // 在此调用getLoginToken接口

    //                     phoneNumberServer.getLoginToken({
    //                       authPageOption: {
    //                           navText: '测试',
    //                           subtitle: '', // 副标题
    //                           isHideLogo: false, // logo显示隐藏
    //                           logoImg: 'XXX',
    //                           btnText: '立即登录',
    //                       },
    //                       protocolPageWatch: res => {
    //                         console.log('预授权',res);
    //                       },
    //                       success: res => {
    //                           console.log('登录成功',res);
    //                           if(res.code===600000){
    //                             that.authorStatus = true;
    //                               // 拿到spToken去服务端发起token验证
    //                               phoneNumberServer.closeLoginPage(); // 手动关闭授权页时调用关闭页面
    //                               res.clearInput(); // 清空输入框并将光标置于第一个输入框
    //                               res.focusOn(2); // 将光标置于第1-4个输入框

    //                               getPhoneWithToken({token: res.spToken}).then(resdata => {
    //                                 console.log('获取手机号',resdata);
    //                                 if(resdata.status == 200){
    //                                   // 绑定手机号
    //                                   console.log('去绑定手机号');
    //                                   that.bindPhone(resdata.data.Data.Mobile, 1)
    //                                 }
    //                               }).catch(resdata => {
    //                                 alert(resdata.message)
    //                               })
    //                           }
    //                       },

    //                       error: res => {
    //                         console.log('登录失败',res);
    //                         that.authorStatus = false;
    //                         // alert('失败',res);
    //                       // 提示用户关闭wifi或者尝试其他登录方案
    //                       },

    //                       watch: function(status, data){
    //                         console.log('-----------------status', status, data);
    //                       // 当status为2时整个流程结束，比如如果按钮有loading状态此处置为false
    //                       },

    //                     });
    //                   }
    //           },

    //           error: function(res){
    //             console.log(res);
    //               // 提示用户关闭Wi-Fi或者尝试其他登录方案
    //           }

    //       });
    //   }).catch(res => {
    //     alert(res.message)
    //   })
    // },
    // // 绑定手机号
    // bindPhone(dataMobile, type){
    //   let that = this;
    //   if(type == 2 && (dataMobile == "" || that.mobileCode == "")){
    //     alert('请完善登录信息')
    //     return false;
    //   }
    //   // if (!/^1[2-9]\d{9}$/.test(dataMobile)) {
    //   //   alert(!this.mobile ? '请输入手机号' : '请填写正确手机号码')
    //   //   return false;
    //   // }
    //   binding({
    //     phone: dataMobile,
    //     sms: type,
    //     sms_code: type == 1 ? 1 : that.mobileCode,
    //     third_user_id: that.trade_type == "WX" ? that.userInfor.unionid : that.aliUserInfor.user_id,
    //     type: that.trade_type == "WX" ? 1 : 2
    //   }).then(res => {
    //     if(res.status == 200){

    //       // 获取商户
    //       // that.getMer();
    //       if(this.userpay.type == "hong"){
    //         this.$router.push({path: '/openPension', query: {userpay: this.userpay, userInfor: this.userInfor, aliAccount: this.aliAccount}});
    //       }else{
    //         this.$router.push({path: '/orderPay', query: {userpay: this.userpay, userInfor: this.userInfor, aliAccount: this.aliAccount }});
    //       }
    //     }
    //   }).catch(res => {
    //     alert(res.message)
    //   })
    // },
    parseParms() {
      if (window.location.href.split("?")[1]) {
        var str = window.location.href.split("?")[1];
        var pieces = str.split("&"), data = {}, i, parts;

        for (i = 0; i < pieces.length; i++) {
          parts = pieces[i].split("=");
          if (parts.length < 2) {
            parts.push("");
          }
          data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
        }
        return data
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

body {
  background: #F8F8F8;
}

* {
  margin: 0;
  padding: 0;
}

#pay {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  box-sizing: border-box;
}

.pay-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginImg {
  width: 100px;
  margin-bottom: 35px;
}

.login-phone {
  border-bottom: 1px solid #aaa;
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 15px;
}

input {
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  font-size: 15px;
}

.get-code {
  color: #FE0032;
}

.login-btn {
  width: 70%;
  text-align: center;
  height: 42px;
  line-height: 42px;
  background: #FE0032;
  color: #ffffff;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay_name {
  width: 6.2rem;
  height: 0.9rem;
  margin: 0.5rem 0 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pay_name_left {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pay_name_left_top {
  font-size: 0.34rem;
  font-weight: 600;
  color: #333333;
}

.pay_name_left_bottom {
  font-size: 0.26rem;
  font-weight: 400;
  color: #999999;
}

.pay_name_right {
  width: 0.9rem;
  height: 0.9rem;
  background: #D8D8D8;
  border: 0.01rem solid #979797;
}

.pay_name_right img {
  width: 100%;
  height: 100%;
  display: block;
}

input::-webkit-input-placeholder {
  /*WebKit browsers*/
  color: #999999;
  font-size: 0.4rem;
  margin-top: 13rem;

}

input::-moz-input-placeholder {
  /*Mozilla Firefox*/
  color: #999999;
  font-size: 0.4rem;
  margin-top: 13rem;

}

input::-ms-input-placeholder {
  /*Internet Explorer*/
  color: #999999;
  font-size: 0.4rem;
  margin-top: 13rem;

}

.pay_input {
  width: 100%;
  height: 3.48rem;
  background: #FFFFFF;
  border-radius: 0.16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pay_input-top {
  width: 6.36rem;
  height: 2.63rem;
  border-bottom: 0.01rem solid #E5E5E5;
}

.pay_input-top-shifu {
  margin: 0.54rem 0 0 0.41rem;
  font-size: 0.26rem;
  font-weight: 400;
  color: #333333;
}

.pay_input-top-manei {
  margin: 0.12rem 0 0 0.41rem;
  height: 1.19rem;
  display: flex;
  align-items: center;
}

.pay_input-top-manei span {
  font-size: 0.64rem;
  font-weight: 600;
  color: #333333;
}

.pay_input-top-manei input {
  width: 100%;
  height: 0.99rem;
  border: none;
  outline: medium;
  margin-left: 0.27rem;
  font-size: 0.9rem;
}

.pay_input-bottom {
  width: 6.36rem;
  height: 0.84rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.24rem;
  font-weight: 400;
  color: #FE0032;
}

.pay_input-bottom-left {
  width: 0.3rem;
  height: 0.3rem;
}

.pay_input-bottom-left img {
  width: 100%;
  height: 100%;
  display: block;
}

.pay_input-bottom-right {
  margin-left: 0.08rem;
}

.pay_bottom {
  width: 6.86rem;
  height: 0.88rem;
  background: linear-gradient(179deg, #FE7577 0%, #FE0032 100%);
  border-radius: 1rem;
  /* margin-top: 7.5rem; */
  position: fixed;
  bottom: 0.5rem;
  text-align: center;
  line-height: 0.88rem;
  font-size: 0.32rem;
  font-weight: 600;
  color: #FFFFFF;
}
</style>
