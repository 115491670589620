<template>
  <div id="pay">
    <div class="pay_name">
      <div class="pay_name_left">
        <div class="pay_name_left_top">付款给商家</div>
        <div class="pay_name_left_bottom" id="mername">{{ merContent.mer_name }}</div>
      </div>
      <div class="pay_name_right">
        <img :src="merContent.mer_banner" id="merbanner" alt="">
      </div>
    </div>
    <div class="pay_input">
      <div class="pay_input-top">
        <div class="pay_input-top-shifu">实付金额</div>
        <div class="pay_input-top-manei">
          <span>￥</span>
          <input type="number" id="textInput" placeholder="请输入金额" v-model="paymentAmount"
                 autofocus="autofocus" maxLength="8"
                 @keyup="paymentAmount = paymentAmount.replace(/[^\d^\.]+/g,'')">
        </div>
      </div>
      <!-- <div class="pay_input-bottom">
                <div class="pay_input-bottom-left">
                    <img src="https://cdn.bjtdba.com/vod/image/2022-10-18/20221018152247280.png" alt="">
                </div>
                <div class="pay_input-bottom-right">储蓄保全国合作伙伴招募中</div>
            </div> -->
    </div>
    <div class="pay_bottomBtn">
      <div class="pay_bottom" @click="payBtn()">立即支付</div>
    </div>

    <div class="login-hint" v-show="this.$isWechat()">如右下方出现《使用完整服务》请点击进行授权。</div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import {
  getMerInfo,
  createOrder,
  jsapiPay
} from '../api/index'
export default {
  name: 'OrderPay',
  props: {
    msg: String
  },
  data() {
    return {
      canClick: true,
      userpay: null,
      userInfor: {},
      aliUserInfor: {},
      aliAccount: {},
      merContent: {
        mer_name: '',
        mer_banner: ''
      },
      paymentAmount: '',
      trade_type: '',
      outTradeNo: '',
    }
  },
  created() {
    //获取参数
    //   this.userpay = this.parseParms()
    //
    //   this.userpay = this.$route.query.userpay;
    //   this.userInfor = this.$route.query.userInfor;
    //   this.aliAccount = this.$route.query.aliAccount;


    this.userpay = JSON.parse(localStorage.getItem('userpay'));
    this.userInfor = JSON.parse(localStorage.getItem('userInfor'));
    this.aliAccount = JSON.parse(localStorage.getItem('aliAccount'));
    // alert(JSON.stringify(this.userpay))
    // alert(JSON.stringify(this.userInfor))



    //跳转微信授权登陆
    //   let local = window.location.href;
    //   if (local.split(':')[0] == 'http') {
    //       local = local.split(':')[0] + 's:' + local.split(':')[1]
    //   } else {
    //       local = window.location.href;
    //   }
    // wx66f5f8def2b755da
    //   let wechatHrefurl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + 'wx6ac5b448b75117d9' +
    //       "&redirect_uri=" + encodeURIComponent(local) +
    //       "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";



    //不是微信并且不是支付宝
    if (!this.$isWechat() && !this.$isAlipay()) {
      alert('请使用微信或支付宝扫码');
    }



    //   //判断是不是微信
    if (this.$isWechat()) {
      this.trade_type = "WX"
      //   if (!this.userpay.code || this.userpay.code == "" || this.userpay.code == null) {
      //       window.location.href = wechatHrefurl;
      //   } else {
      //       this.user()
      //   }
    }
    //   //判断是不是支付宝
    if (this.$isAlipay()) {
      this.trade_type = "ALI"
      // alert(JSON.stringify(this.aliAccount))
      //   if (!this.userpay.auth_code || this.userpay.auth_code == null || this.userpay.auth_code == "") {
      //       local = local.replace('#/', '');

      //       let aliHrefurl =
      //           "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021003191617008&scope=auth_user&redirect_uri=" +
      //           encodeURIComponent(local)
      //       window.location.href = aliHrefurl;

      //   } else {
      //       this.aliUser()
      //   }
    }

    this.getMer();
  },
  // 引入支付宝jsapi
  mounted() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
    document.body.appendChild(s);
    window.onload = () => { //初始化产生一个全局变量 AlipayJSBridge
      this.readys()
    }
    // 监听返回按钮事件
    window.addEventListener('popstate', function() {
      // 关闭页面
      window.close();
    });

    // 修改浏览器历史记录
    const state = {
      page: 'main'
    };
    history.pushState(state, '', '');
  },
  methods: {
    readys(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener('AlipayJSBridgeReady', callback, false);
      }
    },
    //   user() {
    //     wechatAuthorization(this.userpay.code).then(res => {
    //       console.log(res);
    //       if(res.status == 200){
    //         this.userInfor = res.data;
    //         this.initWxConfig(res.data.unionid);
    //       }
    //     }).catch(res => {
    //       this.$message.error(res.message);
    //     })
    //   },
    //   aliUser() {
    //     console.log(this.userpay, this.userpay.code);
    //     aliSign({code: this.userpay.auth_code, type: "h5pay"}).then(res => {
    //       if(res.status == 200){
    //         this.aliUserInfor = res.data;
    //         this.initAliConfig(res.data.user_id);
    //       }
    //     }).catch(res => {
    //       this.$message.error(res.message);
    //     })
    //   },
    //   // 微信授权登录
    //   initWxConfig(userOpenid) {
    //     wxcheck(userOpenid).then(res => {
    //       if(res.status == 400){
    //         // 未绑定手机号，获取token，绑定手机号
    //         this.getALToken();
    //       }
    //       if(res.status == 200){
    //         // 已绑定手机号，获取商户
    //         this.getMer();
    //       }
    //     }).catch(res => {
    //       this.$message.error(res.message);
    //     })
    //   },
    //   // 支付宝授权登录
    //   initAliConfig(userOpenid) {
    //     alicheck(userOpenid).then(res => {
    //       if(res.status == 200){

    //         if(res.data.code == 400){
    //           // 未绑定手机号，获取token，绑定手机号

    //           this.getALToken();

    //         }else{
    //           // 已绑定手机号，获取商户
    //           this.aliAccount = res.data.user;
    //           this.getMer();
    //         }

    //       }
    //     }).catch(res => {
    //       this.$message.error(res.message);
    //     })
    //   },
    //   // 阿里一键登录
    //   getALToken(){
    //     let that = this;
    //     getAuthToken({url:"https://offlinepay-test.bjtdba.com/", origin:"https://offlinepay-test.bjtdba.com"}).then(resT => {
    //       console.log(resT);
    //       var phoneNumberServer = new PhoneNumberServer();

    //         phoneNumberServer.checkLoginAvailable({
    //             accessToken: resT.data.TokenInfo.AccessToken,
    //             jwtToken:resT.data.TokenInfo.JwtToken,
    //             success:function(data){
    //                 console.log(data);
    //                     if(data.code===600000){
    //                         // 在此调用getLoginToken接口

    //                       phoneNumberServer.getLoginToken({
    //                         authPageOption: {
    //                             navText: '测试',
    //                             subtitle: '', // 副标题
    //                             isHideLogo: false, // logo显示隐藏
    //                             logoImg: 'XXX',
    //                             btnText: '立即登录',
    //                         },
    //                         protocolPageWatch: res => {
    //                           console.log('预授权',res);
    //                         },
    //                         success: res => {
    //                             console.log('登录成功',res);
    //                             if(res.code===600000){
    //                                 // 拿到spToken去服务端发起token验证
    //                                 phoneNumberServer.closeLoginPage(); // 手动关闭授权页时调用关闭页面
    //                                 res.clearInput(); // 清空输入框并将光标置于第一个输入框
    //                                 res.focusOn(2); // 将光标置于第1-4个输入框

    //                                 // res.setMessage({  // 设置弹出toast提示框（有默认样式）
    //                                 //     showMessage: true, // 是否弹出Toast提示框
    //                                 //     messageContent: 'test content', // 弹出内容
    //                                 //     messageStyle: {  // 自定义弹窗样式，写入css样式即可
    //                                 //         color: '#fff',
    //                                 //         borderRadius: '8px',
    //                                 //     },
    //                                 //     time: 3000 // 弹出时间/ms，默认3000毫秒
    //                                 // });

    //                                 getPhoneWithToken({token: res.spToken}).then(resdata => {
    //                                   console.log('获取手机号',resdata);
    //                                   if(resdata.status == 200){
    //                                     // 绑定手机号
    //                                     console.log('去绑定手机号');
    //                                     that.bindPhone(resdata.data.Data)
    //                                     // wxcheck(this.userInfor.unionid).then(res => {
    //                                     //   console.log('授权登录',res);
    //                                     // }).catch(res => {
    //                                     //   this.$message.error(res.message);
    //                                     // })
    //                                   }
    //                                 }).catch(resdata => {
    //                                   that.$message.error(resdata.message);
    //                                 })
    //                             }
    //                         },

    //                         error: res => {
    //                           console.log('登录失败',res);
    //                           alert('失败',res);
    //                         // 提示用户关闭wifi或者尝试其他登录方案
    //                         },

    //                         watch: function(status, data){
    //                           console.log('-----------------status', status, data);
    //                         // 当status为2时整个流程结束，比如如果按钮有loading状态此处置为false
    //                         },

    //                       });
    //                     }
    //             },

    //             error: function(res){
    //               console.log(res);
    //                 // 提示用户关闭Wi-Fi或者尝试其他登录方案
    //             }

    //         });
    //     }).catch(res => {
    //       this.$message.error(res.message);
    //     })
    //   },
    //   // 绑定手机号
    //   bindPhone(dataMobile){
    //     let that = this;
    //     binding({
    //       phone: dataMobile.Mobile,
    //       sms: 1,
    //       sms_code: 1,
    //       third_user_id: that.trade_type == "WX" ? that.userInfor.unionid : that.aliUserInfor.user_id,
    //       type: that.trade_type == "WX" ? 1 : 2
    //     }).then(res => {
    //       if(res.status == 200){

    //         // 获取商户
    //         that.getMer();
    //       }
    //     }).catch(res => {
    //       that.$message.error(res.message);
    //     })
    //   },
    getMer() {
      // 获取商户
      getMerInfo(this.userpay.mer_id).then(resMer => {
        if (resMer.status == 200) {
          this.merContent = resMer.data;
        } else {
          // alert(resMer.message)
        }
      }).catch(resMer => {
        alert(resMer.message)
      })
    },
    // 创建订单
    payBtn() {
      let that = this;
      // if (!this.paymentAmount) {
      //     alert('请输入正确金额，金额不能小于 0.1 元')
      // }
      if (!this.paymentAmount || this.paymentAmount < 0.1) {
        alert('请输入有效金额,且金额不能小于 0.1 元')
        return
      }
      if(!that.canClick){
        return
      }
      that.canClick = false;
      // 两秒后重新启用点击
      setTimeout(() => {
        that.canClick = true;
      },2000);
      createOrder({
        money: that.paymentAmount,
        mer_id: that.merContent.mer_id,
        trade_type: that.trade_type,
        avatar: that.trade_type == "WX" ? that.userInfor.headimgurl : that.aliAccount.avatar,
        nickname: that.trade_type == "WX" ? that.userInfor.nickname : that.aliAccount.nickname,
        pay_user_id: that.trade_type == "WX" ? that.userInfor.unionid : that.aliAccount.user_id
      }).then(res => {
        // alert(JSON.stringify(res))
        if (res.status == 200) {
          localStorage.setItem('out_trade_no', res.data.out_trade_no);
          that.outTradeNo = res.data.out_trade_no;
          // 支付
          jsapiPay({
            out_trade_no: res.data.out_trade_no,
            type: 'HF'
          }).then(resPay => {
            if (resPay.status == 200) {


              if (that.trade_type == 'WX') {
                that.paymentByWxpay(resPay.data)
              } else if (that.trade_type == 'ALI') {
                that.paymentByAlipay(resPay.data)
              }
            }
          }).catch(resPay => {
            alert(resPay.message)
          })

        } else {
          alert(res.message)
        }
      }).catch(resMer => {
        alert(resMer.message)
      })
    },
    // 微信支付
    paymentByWxpay(data) {
      let that = this;
      var pay_info = JSON.parse(data.expend.pay_info)
      localStorage.setItem('config', data.expend.pay_info)
      wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
        appId: pay_info.appId, // 必填，公众号的唯一标识
        timestamp: pay_info.timeStamp, // 必填，生成签名的时间戳
        nonceStr: pay_info.nonceStr, // 必填，生成签名的随机串
        signature: pay_info.paySign, // 必填，签名
        jsApiList: ['chooseWXPay'], // 必填，需要使用的 JS 接口列表 支付
        openTagList: ["wx-open-launch-weapp"] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });

      wx.ready(function() {
        wx.checkJsApi({
          jsApiList: ['chooseWXPay'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function(res) {
            console.log(res, '微信支付返回值');
          },
          fail: function(res) {
            console.log(res, '错误');
          }
        });
        wx.chooseWXPay({
          appId: pay_info.appId,
          timestamp: pay_info.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: pay_info.nonceStr, // 支付签名随机串，不长于 32 位
          package: pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
          signType: pay_info.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: pay_info.paySign, // 支付签名
          success: (res) => {
            console.log(res);
            that.$router.push({
              path: '/success'
            });
            // 支付成功后的回调函数
            // location.replace("./Paytocomplete.html")
            // window.location.replace("./Paytocomplete.html")
            // window.location.href = path + "./Paytocomplete.html";
            //结束loading
          },
          // cancel: function (res) {
          //      结束loading
          //     alert('取消支付');
          // },
          fail: function(res) {
            //结束loading
            alert('支付失败', res);
          }
        });
      });
      wx.error(function(res) {
        console.log('res', res);
      });
    },
    // 支付宝支付
    paymentByAlipay(data) {
      let that = this;
      var zfbPay_info = JSON.parse(data.expend.pay_info)
      window.AlipayJSBridge.call("tradePay", {
        tradeNO: zfbPay_info.tradeNO, // 必传，此使用方式下该字段必传
      }, function(result) {
        if (result.resultCode == '9000') {
          that.$router.push({
            path: '/success'
          });
          // location.replace("./Paytocomplete.html?out_trade_no=" + that.outTradeNo);
          that.paymentAmount = '';
        } else {
          console.log("支付失败");
          //   alert("支付失败")
        }
      });
    },
    parseParms() {
      if (window.location.href.split("?")[1]) {
        var str = window.location.href.split("?")[1];
        var pieces = str.split("&"),
            data = {},
            i, parts;

        for (i = 0; i < pieces.length; i++) {
          parts = pieces[i].split("=");
          if (parts.length < 2) {
            parts.push("");
          }
          data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
        }
        return data
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

body {
  background: #F8F8F8;
}

* {
  margin: 0;
  padding: 0;
}

#pay {
  width: 100%;
  height: 100vh;
  padding-top: 30px;
  box-sizing: border-box;
  background: #F8F8F8;
}

.pay_name {
  width: 100%;
  height: 55px;
  /* margin: 0.5rem 0 0.5rem 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  box-sizing: border-box;
}

.pay_name_left {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
}

.pay_name_left_top {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
}

.pay_name_left_bottom {
  /* font-size: 0.26rem; */
  font-weight: 400;
  color: #999999;
}

.pay_name_right {
  width: 0.9rem;
  height: 0.9rem;
  background: #D8D8D8;
  border: 0.01rem solid #979797;
}

.pay_name_right img {
  width: 100%;
  height: 100%;
  display: block;
}

input::-webkit-input-placeholder {
  /*WebKit browsers*/
  color: #999999;
  font-size: 0.4rem;
  margin-top: 13rem;

}

input::-moz-input-placeholder {
  /*Mozilla Firefox*/
  color: #999999;
  font-size: 0.4rem;
  margin-top: 13rem;

}

input::-ms-input-placeholder {
  /*Internet Explorer*/
  color: #999999;
  font-size: 0.4rem;
  margin-top: 13rem;

}

.pay_input {
  width: 100%;
  height: 163px;
  background: #FFFFFF;
  border-radius: 0.16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.pay_input-top {
  width: 100%;
  height: 115px;
  border-bottom: 0.01rem solid #E5E5E5;
}

.pay_input-top-shifu {
  margin: 20px 0 0 25px;
  font-size: 0.26rem;
  font-weight: 400;
  color: #333333;
}

.pay_input-top-manei {
  margin: 15px 0 0 25px;
  height: 50px;
  display: flex;
  align-items: center;
}

.pay_input-top-manei span {
  font-size: 0.64rem;
  font-weight: 600;
  color: #333333;
}

.pay_input-top-manei input {
  width: 100%;
  height: 50px;
  border: none;
  outline: medium;
  margin-left: 0.27rem;
  font-size: 40px;
}

.pay_input-bottom {
  /* width: 6.36rem; */
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.24rem;
  font-weight: 400;
  color: #FE0032;
}

.pay_input-bottom-left {
  width: 0.3rem;
  height: 0.3rem;
}

.pay_input-bottom-left img {
  width: 100%;
  height: 100%;
  display: block;
}

.pay_input-bottom-right {
  margin-left: 0.08rem;
}

.pay_bottomBtn {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 2rem;
}

.pay_bottom {
  width: 100%;
  height: 43px;
  background: linear-gradient(179deg, #FE7577 0%, #FE0032 100%);
  border-radius: 1rem;
  /* margin-top: 7.5rem; */
  /* position: fixed;
  bottom: 0.5rem; */
  text-align: center;
  line-height: 43px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
}

.login-hint {
  color: #FE0032;
  font-size: 14px;
  position: fixed;
  bottom: 0.8rem;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}
</style>

